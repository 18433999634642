import { render, staticRenderFns } from "./newsItem.vue?vue&type=template&id=431bae09&scoped=true&"
import script from "./newsItem.vue?vue&type=script&lang=ts&"
export * from "./newsItem.vue?vue&type=script&lang=ts&"
import style0 from "./newsItem.vue?vue&type=style&index=0&id=431bae09&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "431bae09",
  null
  
)

export default component.exports