











































import { defineComponent, ref, onMounted, watch } from '@vue/composition-api'
import $http from '@/api'

import FirstNewsItem from './components/firstNewsItem.vue'
import NewsItem from './components/newsItem.vue'
import NewsDetail from './components/newsDetail.vue'
import { newsItemType, NEWS_TYPE } from '@/common/newsData'

export default defineComponent({
  name: 'News',
  components: {
    FirstNewsItem,
    NewsItem,
    NewsDetail
  },
  setup(props, { root }) {
    const rn = 5
    // const { news, media } = useNewsData()

    const isMore = ref<boolean>(false)
    const idCopy = ref<number>(0)
    const newsIndex = ref<string>('')
    const activeName = ref<string>('')
    const newsList = ref<newsItemType[]>([])

    const pageNum = ref<number>(1)
    const total = ref<number>(0)

    const getNewsList = async (type: string) => {
      const q = {
        typeArrStr: String(NEWS_TYPE[type]),
        pageNum: pageNum.value,
        pageSize: rn,
        newsIdArrStr: newsList.value
          .map((item: newsItemType) => item.newsId)
          .join(',')
      }
      const { data } = await $http.getNewsList(q)
      if (!data.list?.length) {
        isMore.value = false
        return
      }
      total.value = data.total
      newsList.value.push(...data.list)
      if (newsList.value.length < total.value) {
        isMore.value = true
      }
    }

    const getMore = () => {
      pageNum.value++
      getNewsList(activeName.value)
    }

    const tabClickHandle = (tab: any) => {
      const name = tab.name
      root.$router.push(`/news?type=${name}`).catch(() => {})
      newsList.value = []
      idCopy.value = 0
      pageNum.value = 1
      // getNewsList(name)
    }

    const clearData = () => {
      isMore.value = false
      idCopy.value = 0
      activeName.value = ''
      newsList.value = []
    }

    const init = (val: any) => {
      clearData()
      const { type, index = '', id = 0 } = val
      activeName.value = String(type)
      newsIndex.value = String(index)
      idCopy.value = Number(id)
      !id && getNewsList(activeName.value)
    }

    const clickHandle = (id: number, index: number) => {
      idCopy.value = id
      newsIndex.value = String(index)
      root.$router
        .push(`/news?type=${activeName.value}&index=${index}&id=${id}`)
        .catch(() => {})
    }

    onMounted(() => {
      watch(
        () => root.$route.query,
        (val: any) => {
          init(val)
        },
        { deep: true, immediate: true }
      )
    })

    return {
      activeName,
      newsList,
      isMore,
      idCopy,
      newsIndex,
      getMore,
      getNewsList,
      tabClickHandle,
      clickHandle
    }
  }
})
