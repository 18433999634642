






























import { defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { newsItemType } from '@/common/newsData'
import { parseTime } from '@/utils/date'

export default defineComponent({
  name: 'FirstNewsItem',
  props: {
    data: {
      type: Object as PropType<newsItemType>
    },
    index: {}
  },
  setup(props, { emit }) {
    const item = ref<newsItemType>()

    watch(
      () => props.data,
      (val: any) => {
        item.value = val
      },
      {
        deep: true,
        immediate: true
      }
    )

    const clickHandle = () => {
      emit('click-handle', item.value?.newsId, props.index)
    }

    return {
      item,
      clickHandle,
      parseTime
    }
  }
})
