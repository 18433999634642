























































import {
  defineComponent,
  reactive,
  ref,
  toRefs,
  watchEffect
} from '@vue/composition-api'
import $http from '@/api'
import WangEditor from '@/components/wang-editor/index.vue'
import { linkDownload } from '@/utils/linkDownload'

import { NewsDetail, newsItemType, NEWS_TYPE } from '@/common/newsData'
import { parseTime } from '@/utils/date'

export default defineComponent({
  name: 'NewsDetail',
  components: {
    WangEditor
  },
  props: {
    id: {
      type: Number
    },
    index: {
      type: String
    },
    type: {
      type: String
    }
  },
  setup(props, { emit }) {
    const state = reactive({
      newsId: 0,
      newsIndex: '',
      newsType: '',
      editorVisible: false,
      attachList: []
    })
    const detail = ref<NewsDetail | any>({ title: '' })
    const preDetail = ref<newsItemType | any>({ title: '' })
    const nextDetail = ref<newsItemType | any>({ title: '' })

    const getData = async () => {
      if (state.newsId && state.newsType) {
        try {
          state.editorVisible = false
          const q = {
            newsId: state.newsId,
            type: NEWS_TYPE[state.newsType] || 0
          }
          const { data } = await $http.getNewsDetail(q)
          detail.value = data
          if (data.attachList) {
            try {
              state.attachList = JSON.parse(data.attachList)
            } catch (e) {}
          }
          state.editorVisible = true

          if (state.newsIndex && !isNaN(+state.newsIndex)) {
            const {
              data: { list }
            } = await $http.getNewsList({
              // type: [NEWS_TYPE[state.newsType] || 0],
              typeArrStr: String(NEWS_TYPE[state.newsType]),
              pageSize: 10,
              pageNum: Math.floor(+state.newsIndex / 10) + 1
            })
            const index = list.findIndex((item: newsItemType) => {
              return state.newsId === item.newsId
            })
            if (index > 0) {
              preDetail.value = list[index - 1]
            } else {
              preDetail.value = {
                title: ''
              }
            }

            if (index < list.length - 1) {
              nextDetail.value = list[index + 1]
            } else {
              nextDetail.value = {
                title: ''
              }
            }
          }
        } catch (e) {}
      }
    }

    watchEffect(() => {
      if (props.id && props.id !== state.newsId) {
        state.newsId = props.id
        getData()
      }
      if (props.index && props.index !== state.newsIndex) {
        state.newsIndex = props.index
        getData()
      }
      if (props.type && props.type !== state.newsType) {
        state.newsType = props.type
        getData()
      }
    })

    const clickHandle = (id: number, index: number) => {
      emit('click-handle', id, index)
    }

    const downloadAttach = linkDownload

    return {
      ...toRefs(state),
      detail,
      preDetail,
      nextDetail,
      clickHandle,
      parseTime,
      downloadAttach
    }
  }
})
